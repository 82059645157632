import { styled, css } from "styled-components";
import theme from "styled-theming";

import { default as DefaultImage } from "components/Image";
import { MQ } from "style/mediaQueries";

export const Image = styled(DefaultImage)`
    &.embedded {
        margin-right: auto;
        width: auto;
    }

    &:not(.embedded) {
        aspect-ratio: 2/3;
        background-color: var(--color-blue-d10);
        height: auto;
        max-height: 50vh;
        width: 100%;

        ${theme("blockInBlock", {
            true: css`
                aspect-ratio: unset;
                margin-bottom: 2.1875rem;
                max-height: unset;
                min-height: unset;
            `,
        })}

        ${MQ.FROM_M} {
            ${theme("blockInBlock", {
                true: css`
                 margin-bottom: 3.5rem;
                `,
            })}
        }

        ${MQ.FROM_L} {
            aspect-ratio: unset;
            height: 75vh;
            max-height: 75vh;
            ${theme("isInEditMode", {
                true: css`
                    height: auto;
                    min-height: 31.25rem;
                `,
            })}
            ${theme("blockInBlock", {
                true: css`
                    height: auto;
                    margin-bottom: 3.5rem;
                `,
            })}
        }

        ${MQ.FROM_XL} {
            ${theme("blockInBlock", {
                true: css`
                    margin-bottom: 4.375rem;
                `,
            })}
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
`;
