import { FormHelperText } from "@mui/material";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import { styleBodyL, styleHeadingM, styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const StyledForm = styled.form`
    ${columnGap};
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.1875rem;
    padding-bottom: 4.8125rem;
    position: relative;
    width: 100%;

    ${MQ.FROM_M} {
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 5.25rem;
    }
    ${theme("formGuide", {
        true: css`
            display: grid;
            grid-column: col-start 1 / span 12;
            grid-template-columns: repeat(1, [col-start] 1fr);
            ${MQ.FROM_M} {
                grid-template-columns: repeat(12, [col-start] 1fr);
            }
        `
    })}
`;

export const FormGuideHeader = styled.div`
    align-self: flex-start;
    grid-column: col-start 1 / span 12;
    margin-bottom: 3.5rem;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 6;
    }
`;

export const FormGuideHeading = styled.h2`
    ${styleHeadingXL};
    margin-bottom: 1.75rem;
    margin-top: 0;
`;

export const FormGuideIntroduction = styled.div`
    ${styleBodyL};
    margin-top: 0;
`;

export const FormElements = styled.div`
    ${columnGap}
    display: grid;
    flex-direction: column;
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(2, [col-start] 1fr);
    gap: 0.875rem;
    width: 100%;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(6, [col-start] 1fr);
        gap: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        gap: 1.75rem;
    }

    button[type="submit"] {
        align-self: flex-start;
    }
`;

export const FormMessage = styled(FormHelperText)`
    grid-column: col-start 1 / span 4;

    && {
        ${styleBodyL};
        &.Mui-error {
            color: var(--color-coral);
        }
    }
`;

export const SuccessMessage = styled.div`
    ${styleHeadingM};
    grid-column: col-start 1 / span 4;
    margin: 0;
`;
